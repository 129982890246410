
import { Component, Vue } from 'vue-property-decorator'
import { lang } from '@/lang'

@Component({
  components: {}
})
export default class NoData extends Vue {
  private get text () {
    return lang('MY_NAME_FIND_MORE_DOMAIN')
  }
}
